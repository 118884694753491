<template>
  <v-dialog
    v-model="dialog"
    max-width="650px"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nueva ? 'Nueva bodega' : (editar ? 'Editar bodega' : 'Detalle bodega') }}
        <v-spacer></v-spacer>
        <template v-if="!p_nueva && bodega.inhabilitada != 1">
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-top mt-0"
            hide-details
            dense
          ></v-switch>
          <v-divider
            class="mx-3"
            vertical
          ></v-divider>
        </template>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8 pb-2">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="bodega.nombre"
                label="Nombre"
                :rules="editar ? [rules.required] : []"
                :autofocus="editar"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="bodega.tipo_codigo"
                label="Tipo"
                item-text="nombre"
                item-value="codigo"
                :items="p_tipos"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="verificar_local()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="bodega.empresa_codigo"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="get_locales(); bodega.local_codigo = null; locales = []"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="bodega.sucursal_codigo"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales_todas"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="get_locales(); bodega.local_codigo = null; locales = []"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="bodega.local_codigo"
                label="Local"
                item-text="nombre"
                item-value="codigo"
                :clearable="editar"
                :items="locales"
                :readonly="!editar || !permite_local"
                :filled="!editar || !permite_local"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                v-model="bodega.superficie"
                label="Superficie (m²)"
                type="number"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-switch
                v-if="!p_nueva"
                v-model="bodega.inhabilitada"
                label="Inhabilitada"
                class="d-flex align-top mt-0"
                :true-value="1"
                :false-value="null"
                :readonly="!editar"
                hide-details
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          class="mr-2"
          :color="editar ? 'error' : 'secondary'"
          :disabled="load"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una bodega
 * 
 *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita una bodega (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import { mapState } from 'vuex'
import { get_nombre_obj_arr } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      editar: false,
      permite_local: true,
      locales: [],
      sucursales_todas: [],
      bodega: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_nueva: Boolean, // para editar una bodega enviar en false, para una bodega nueva en true
    p_bodega: Object, // en caso de ser nueva enviar el objeto vacio {}
    p_tipos: Array    // array de todos los tipos de bodega habilitados
  },
  computed: {
    ...mapState(['empresas']),
    ...mapState('genericosAcc', ['sucursales']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        await this.traer_sucursales()
        this.permite_local = true
        this.locales = []
        if (this.p_nueva) {
          this.editar = true
          // si es nueva setea los campos en null
          this.bodega = {
            empresa_codigo: null,
            tipo_codigo: null,
            sucursal_codigo: null,
            local_codigo: null,
            superficie: null,
            nombre: ''
          }
        } else {
          this.editar = false
          // si la sucursal no existe en el array, la agrega
          const sucursal_cod = this.p_bodega.sucursal_codigo
          if (this.sucursales.filter(suc => suc.id == sucursal_cod).length == 0) {
            this.sucursales_todas.push({
              id: sucursal_cod,
              nombre: this.p_bodega.sucursal_nombre
            })
          }
          // si tiene un local lo agrega al array
          this.agregar_local()
          // crea una copia del prop p_bodega para editarlo
          this.bodega = JSON.parse(JSON.stringify(this.p_bodega))
          // verifica si el tipo de bodega permite locales
          this.verificar_local()
        }
      } else {
        this.$refs.form.resetValidation()
      }
    },
    editar (val) {
      if (val) {
        // trae los locales de la emrpesa y sucursal que no exitan en una bodega
        this.get_locales()
      } else {
        // cuando cancela la edicion vuelve a dejar la bodega como estaba originalmente
        this.bodega = JSON.parse(JSON.stringify(this.p_bodega))
        this.agregar_local()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nueva) {
          await this.$store.dispatch('bodegas/nueva', this.bodega)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
              // emite la accion 'nueva' definifa en el padre
              this.$emit('nueva', res.bodega)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // solo edita si realmente se modifico la bodega
          if (JSON.stringify(this.bodega) != JSON.stringify(this.p_bodega)) {
            await this.$store.dispatch('bodegas/editar', this.bodega)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
                // hace un UPPER del nombre
                this.bodega.nombre = this.bodega.nombre.toUpperCase()
                // obtiene el nombre del tipo, la empresa, la sucursal y el local
                this.bodega.tipo_nombre = get_nombre_obj_arr(this.p_tipos, 'codigo', this.bodega.tipo_codigo)
                this.bodega.empresa_nombre = get_nombre_obj_arr(this.empresas, 'id', this.bodega.empresa_codigo, 'nombre_corto')
                this.bodega.sucursal_nombre = get_nombre_obj_arr(this.sucursales_todas, 'id', this.bodega.sucursal_codigo)
                this.bodega.local_nombre = get_nombre_obj_arr(this.locales, 'codigo', this.bodega.local_codigo)
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', this.bodega)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: `No se realizaron cambios en la bodega ${this.bodega.nombre}: los nuevos valores son iguales a los anteriores`,
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    },
    async get_locales () {
      const empresa = this.bodega.empresa_codigo
      const sucursal = this.bodega.sucursal_codigo
      if (empresa && sucursal) {
        this.$store.state.loading = true
        await this.$store.dispatch('bodegas/get_locales', {
          empresa: empresa,
          sucursal: sucursal
        })
          .then((res) => {
            res.data.forEach(local => {
              this.locales.push(local)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async traer_sucursales () {
      this.$store.state.loading = true
      await this.$store.dispatch('genericosAcc/get_sucursales')
      this.sucursales_todas = []
      this.sucursales.forEach(sucursal => {
        this.sucursales_todas.push(sucursal)
      })
      this.$store.state.loading = false
    },
    agregar_local () {
      this.locales = []
      const local_cod = this.p_bodega.local_codigo
      if (local_cod) {
        this.locales.push({
          codigo: local_cod,
          nombre: this.p_bodega.local_nombre
        })
      }
    },
    verificar_local () {
      const tipo = this.p_tipos.find(t => t.codigo == this.bodega.tipo_codigo)
      this.permite_local = true
      if (tipo.local != 1) {
        this.permite_local = false
        this.bodega.local_codigo = null
      }
    }
  }
}
</script>